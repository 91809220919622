import Layout from "@/layout/Container.vue"
import { clear, getStorage } from "../storage"

export const commonRouter = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: "登录",
    },
    component: () => import("@/views/login")
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: "工作台"
    },
    component: Layout,
    children: [
      {
        path: '/home/index',
        name: 'HomeIndex',
        meta: {
          title: '数据总览',
          parent: '工作台'
        },
        component: () => import("@/views/home/index")
      },
      {
        path: '/home/census',
        name: 'HomeCensus',
        meta: {
          title: '零工统计',
          parent: '工作台'
        },
        component: () => import("@/views/home/census")
      }
    ]
  },
  {
    path: '/zerojob',
    name: 'zerojob',
    meta: {
      title: '零工管理'
    },
    component: Layout,
    children:[
      {
        path: '/zerojob/recruit',
        name: 'RecruitList',
        meta: {
          title: '招工列表',
          parent: '零工管理'
        },
        component: () => import("@/views/zerojob/recruit/index.vue")
      },
      {
        path: '/zerojob/order',
        name: 'OrderList',
        meta: {
          title: '工单列表',
          parent: '零工管理'
        },
        component: () => import("@/views/zerojob/order/index.vue")
      },
      {
        path: '/zerojob/drawmoney',
        name: 'drawmoneyList',
        meta: {
          title: '提现审核',
          parent: '零工管理'
        },
        component: () => import("@/views/zerojob/drawmoney/index.vue")
      },
      {
        path: '/zerojob/serveOrder',
        name: 'ServeOrderList',
        meta: {
          title: '服务订单',
          parent: '零工管理'
        },
        component: () => import("@/views/zerojob/serveOrder/index.vue")
      },
      // {
      //   path: '/zerojob/safe',
      //   name: 'drawmoneySafe',
      //   meta: {
      //     title: '投保列表',
      //     parent: '零工管理'
      //   },
      //   component: () => import("@/views/zerojob/safe/index.vue")
      // }
    ]
  },
  {
    path: '/classify',
    name: 'classify',
    meta: {
      title: '分类管理'
    },
    component: Layout,
    children:[
      {
        path: '/classify/stair',
        name: 'classifystair',
        meta: {
          title: '一级分类',
          parent: '分类管理'
        },
        component: () => import("@/views/classify/stair/index.vue")
      },
      {
        path: '/classify/twolevel',
        name: 'classifytwolevel',
        meta: {
          title: '二级分类',
          parent: '分类管理'
        },
        component: () => import("@/views/classify/twolevel/index.vue")
      },
      {
        path: '/classify/norms',
        name: 'classifynorms',
        meta: {
          title: '服务商品',
          parent: '分类管理'
        },
        component: () => import("@/views/classify/norms/index.vue")
      }
    ]
  },
  
  {
    path: '/user',
    name: 'User',
    meta: {
      title: '用户管理'
    },
    component: Layout,
    children:[
      {
        path: '/user/list',
        name: 'UserList',
        meta: {
          title: '用户列表',
          parent: '用户管理'
        },
        component: () => import("@/views/user/list")
      },
      {
        path: '/user/ranking',
        name: 'UserRankingt',
        meta: {
          title: '零工排名',
          parent: '用户管理'
        },
        component: () => import("@/views/user/ranking")
      },
      // {
      //   path: '/user/rightsOrder',
      //   name: 'UserRightsOrder',
      //   meta: {
      //     title: '权益订单',
      //     parent: '用户管理'
      //   },
      //   component: () => import("@/views/user/rightsOrder")
      // },
      // {
      //   path: '/user/rightsSet',
      //   name: 'UserRightsSet',
      //   meta: {
      //     title: '权益设置',
      //     parent: '用户管理'
      //   },
      //   component: () => import("@/views/user/rightsSet")
      // }
    ]
  },
  {
    path: '/guide',
    name: 'Guide',
    meta: {
      title: "使用指南",
    },
    component: Layout,
    children: [
      {
        path: '/guide/list',
        name: 'GuideList',
        meta: {
          title: '指南列表',
          parent: '使用指南'
        },
        component: () => import("@/views/guide/list")
      },
      {
        path: '/guide/clause',
        name: 'GuideClause',
        meta: {
          title: '接单必看',
          parent: '使用指南'
        },
        component: () => import("@/views/guide/clause/index")
      },
      {
        path: '/guide/rules',
        name: 'GuideRules',
        meta: {
          title: '平台规则',
          parent: '使用指南'
        },
        component: () => import("@/views/guide/rules/index")
      },
    ]
  },
  {
    path: '/ad',
    name: 'Ad',
    meta: {
      title: "广告管理",
    },
    component: Layout,
    children: [
      {
        path: '/ad/banner',
        name: 'BannerList',
        meta: {
          title: '轮播图列表',
          parent: '广告管理'
        },
        component: () => import("@/views/ad/banner/list")
      },
      {
        path: '/ad/bill',
        name: 'BillList',
        meta: {
          title: '海报管理',
          parent: '广告管理'
        },
        component: () => import("@/views/ad/bill/list")
      },
      {
        path: '/ad/activity',
        name: 'ActivityList',
        meta: {
          title: '活动设置',
          parent: '广告管理'
        },
        component: () => import("@/views/ad/activity/list")
      },
    ]
  },
  {
    path: '/agency',
    name: 'agency',
    meta: {
      title: "代理商管理",
    },
    component: Layout,
    children: [
      {
        path: '/agency/list',
        name: 'AgencyList',
        meta: {
          title: '代理商列表',
          parent: '代理商管理'
        },
        component: () => import("@/views/agency/list/index")
      },
      {
        path: '/agency/income',
        name: 'AgencyIncome',
        meta: {
          title: '代理商收益',
          parent: '代理商管理'
        },
        component: () => import("@/views/agency/income/index")
      },
      {
        path: '/agency/commision',
        name: 'AgencyCommision',
        meta: {
          title: '抽佣设置',
          parent: '代理商管理'
        },
        component: () => import("@/views/agency/commision/index")
      },
    ]
  },
  {
    path: '/company',
    name: 'Company',
    meta: {
      title: '企业管理'
    },
    component: Layout,
    children:[
      {
        path: '/company/enter',
        name: 'CompanyEnter',
        meta: {
          title: '驻店员列表',
          parent: '企业管理'
        },
        component: () => import("@/views/company/enter/index")
      },
      {
        path: '/company/list',
        name: 'CompanyList',
        meta: {
          title: '企业列表',
          parent: '企业管理'
        },
        component: () => import("@/views/company/list/index")
      },
      {
        path: '/organization/list',
        name: 'OrganizationList',
        meta: {
          title: '机构列表',
          parent: '企业管理'
        },
        component: () => import("@/views/company/organization/index")
      },
    ]
  },
  {
    path: '/area',
    name: 'Area',
    meta: {
      title: '区域管理'
    },
    component: Layout,
    children:[
      {
        path: '/openArea/list',
        name: 'OpenAreaList',
        meta: {
          title: '开通地区',
          parent: '区域管理'
        },
        component: () => import("@/views/area/openArea/index")
      },
      {
        path: '/serveArea/list',
        name: 'ServeAreaList',
        meta: {
          title: '下单地区',
          parent: '区域管理'
        },
        component: () => import("@/views/area/serveArea/index")
      }
    ]
  },
  {
    path: '/shop',
    name: 'Shop',
    meta: {
      title: '零工门店'
    },
    component: Layout,
    children:[
      {
        path: '/shop/list',
        name: 'ShopList',
        meta: {
          title: '门店列表',
          parent: '零工门店'
        },
        component: () => import("@/views/shop/index")
      }
    ]
  },
  {
    path: '/report',
    name: 'Report',
    meta: {
      title: '客服管理'
    },
    component: Layout,
    children:[
      {
        path: '/apply/list',
        name: 'ApplyList',
        meta: {
          title: '介入列表',
          parent: '介入管理'
        },
        component: () => import("@/views/apply/list")
      },
      {
        path: '/report/list',
        name: 'ReportList',
        meta: {
          title: '举报列表',
          parent: '举报管理'
        },
        component: () => import("@/views/report/list")
      },
      {
        path: '/feedback/list',
        name: 'FeedbackList',
        meta: {
          title: '反馈列表',
          parent: '意见反馈'
        },
        component: () => import("@/views/feedback/list")
      },
      {
        path: '/news/list',
        name: 'NewsList',
        meta: {
          title: '新闻资讯',
          parent: '零工资讯'
        },
        component: () => import("@/views/news/index")
      },
      {
        path: '/version/list',
        name: 'VersionList',
        meta: {
          title: '版本列表',
          parent: '版本管理'
        },
        component: () => import("@/views/version/list")
      },
    ]
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      title: "账号管理",
    },
    component: Layout,
    children: [
      {
        path: '/account/list',
        name: 'AccountList',
        meta: {
          title: '账号列表',
          parent: '账号管理'
        },
        component: () => import("@/views/account/list")
      },
    ]
  },
  
  
  {
    path: '*',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import("@/views/404")
  }
]


// 以下代码是生成动态路由
// function importComponent(arr, pas) {
//   arr.forEach(item => {
//     if(!!item.children) {
//       item.component = Layout
//       item.name = item.path.replace(new RegExp("/","gm"),"")
//       if (!!item.children.length) {
//         importComponent(item.children, item)
//       }
//     } else {
//       item.component = (resolve) => require([`@/views${item.path}`], resolve)
//       item.name = item.path.replace(new RegExp("/","gm"),"")
//       item.meta = {
//         parent: pas ? pas.value : '',
//         title: item.value
//       }
//     }
//   });
// }

// export function initRouter() {
//   const roleRouter = getStorage('router')
//   if(roleRouter) {
//     importComponent(roleRouter)
//     console.log(roleRouter);
//     commonRouter.splice(2,0,...roleRouter)
//     router.matcher = new Router({
//       mode: 'history',
//       routes: commonRouter,
//     }).matcher
//   } else {
//     clear()
//     router.replace("/login")
//   }
// }

