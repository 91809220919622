import axios from "axios"
import { getStorage, clear } from "../storage"
import { sign } from '@/util/sign.js'
import { Message } from "element-ui"


const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 600000
})

// 添加请求拦截器
http.interceptors.request.use(function (req) {
  console.log(req,"req")
  if(req.method == 'get'){
    req.data = req.params;
    req.data.timestamp = new Date().getTime();
  }else{
    req.data.timestamp = new Date().getTime();
    if(req.data.type == 'upload'){
      req.data.formData.append('sign',sign({timestamp:req.data.timestamp, ...req.data.obj}));
      req.data.formData.append('timestamp', req.data.timestamp);
      req.data = req.data.formData;
    }
  }
  
  req.data.sign = sign(req.data);
  // 添加时间戳和签名

  req.headers.token = getStorage("token")

  return req;
})

// 添加响应拦截器
http.interceptors.response.use(function (res) {
  const {code, msg, data} = res.data
  if(code === -10000) {
    clear() // 如果token过期，就清除token
    return Promise.reject(res.data)
  } else if (code === -1) {
    Message.error(msg);
    return Promise.reject(res.data)
  } else {
    return res.data
  }
}, function (res) {
  return Promise.reject(res.data)
})

export default http
