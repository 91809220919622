import Router from "vue-router"
import router from "@/router"
import { commonRouter } from "../router/routes"

export const setStorage = (key, val) => {
  localStorage.setItem(key, typeof val === "object" ? JSON.stringify(val) : val)
}

export const getStorage = (key) => {
  const val = localStorage.getItem(key)
  try {
    return JSON.parse(val)
  } catch (error) {
    return val
  }
}

export const remove = (key) => {
  localStorage.removeItem(key)
}

export const clear = () => {
  const newRouter = new Router({
    mode: "history",
    routes: commonRouter
  })
  router.matcher = newRouter.matcher
  localStorage.clear()
}