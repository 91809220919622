<template>
  <el-aside width="auto" style="background:#fff;">
    <el-menu
      class="menu__wrap el-menu-vertical-demo"
      router
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened="false"
    >
      <el-submenu
        v-for="(item) in routes"
        :key="item.id"
        :index="item.path">
        <template>
          <template slot="title">
            <div :class="'icon-'+ (item.path).substr(1)" ></div>
            <el-badge v-if="(item.name == 'Report' && notice.customer_unread == 1)" is-dot class="item">{{ item.meta.title }}</el-badge>
            <span v-else>{{ item.meta.title }}</span>
          </template>
          <el-menu-item-group>
            <template v-for="child in item.children">
              <el-menu-item
                :key="child.name"
                :index="child.path">
                <el-badge
                  v-if="(child.name == 'ApplyList' && notice.intervene_unread == 1 ) || (child.name == 'ReportList' && notice.report_unread == 1 ) || (child.name == 'FeedbackList' && notice.feedback_unread == 1 )" 
                  is-dot class="item">{{ child.meta.title }}</el-badge>
                <span v-else>{{ child.meta.title }}</span>
              </el-menu-item>
            </template>
          </el-menu-item-group>
        </template>
      </el-submenu>

    </el-menu>
  </el-aside>
</template>
<script>
import { getStorage } from "@/storage"
export default {
  name: 'LayoutNaviBar',
  props: ['isCollapse'],
  data() {
    return {
      routes: [],
      notice: {
        intervene_unread: 1, //介入未读：1-未读
        report_unread: 1, //举报未读：1-未读
        feedback_unread: 1, //反馈未读：1-未读
        customer_unread: 1 //客服管理未读：1-未读
      }
    }
  },
  watch: {
    $route(newRoute) {
      this.getNotice();
    }
  },
  mounted() {
    this.routes = this.$router.options.routes.slice(2, this.$router.options.routes.length - 1)
    console.log(this.routes)
    console.log("999999")
    // this.routes = getStorage('router')

    this.getNotice();
  },
  methods:{
    getNotice() {
      this.$http.get('/admin/flex_index/notice',{params: {}}).then(res => {
        if(res.code == 1) {
          console.log(res.data,"987************************************")
          this.notice = res.data;
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.menu__wrap {
  height: 100%;
  box-sizing: border-box;
  &::v-deep {
    .el-menu-item-group__title {
      display: none;
    }
  }
  .menu-icon {
    margin-right: 10px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu-item:hover, .el-menu-item:focus {
  background-color:#e6f1fd !important;
}

.menu__wrap ::v-deep .el-badge__content.is-fixed.is-dot {
  right: 5px;
  top: 15px;
}
</style>
<style >
.el-submenu.is-active > .el-submenu__title{
  color: #007DF2 !important;
}
.el-submenu.is-active > .el-submenu__icon-arrow{
  color: #007DF2 !important;
}
.icon-home {
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/home.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-home{
  background-image: url('~@/assets/img/nav/home-active.png');
}
.icon-zerojob{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/zerojob.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-zerojob{
  background-image: url('~@/assets/img/nav/zerojob-active.png');
}
.icon-classify{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/version.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-classify{
  background-image: url('~@/assets/img/nav/version-active.png');
}
.icon-apply{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/apply.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-apply{
  background-image: url('~@/assets/img/nav/apply-active.png');
}
.icon-user{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/user.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-user{
  background-image: url('~@/assets/img/nav/user-active.png');
}
.icon-guide{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/guide.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-guide{
  background-image: url('~@/assets/img/nav/guide-active.png');
}
.icon-report{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/report.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-report{
  background-image: url('~@/assets/img/nav/report-active.png');
}
.icon-ad{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/banner.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-ad{
  background-image: url('~@/assets/img/nav/banner-active.png');
}
.icon-account{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/account.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-account{
  background-image: url('~@/assets/img/nav/account-active.png');
}
.icon-version{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/version.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-version{
  background-image: url('~@/assets/img/nav/version-active.png');
}
.icon-feedback{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/version.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-feedback{
  background-image: url('~@/assets/img/nav/version-active.png');
}
.icon-agency{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/version.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-agency{
  background-image: url('~@/assets/img/nav/version-active.png');
}
.icon-news{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/version.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-news{
  background-image: url('~@/assets/img/nav/version-active.png');
}
.icon-company{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/version.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-company{
  background-image: url('~@/assets/img/nav/version-active.png');
}
.icon-area{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/version.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-area{
  background-image: url('~@/assets/img/nav/version-active.png');
}
.icon-shop{
  width: 20px;
  height: 20px;
  background-image: url('~@/assets/img/nav/version.png');
  display: inline-block;
  margin:0 10px 0 0;
}
.is-active .icon-shop{
  background-image: url('~@/assets/img/nav/version-active.png');
}
</style>
