import router from "@/router"
import { getStorage } from '@/storage'
import { initRouter } from "./routes"

router.beforeEach((to, from, next) => {
  if(getStorage('token')){
    // to.path === "/login" ? next('/login') : next()
    if (router.options.routes.length <= 3) {
      initRouter()
      next(to.path)
    } else {
      to.path === "/login" ? next('/home/index') : next()
    }
  } else {
    to.path === "/login" ? next() : next('/login')
  }
})